import Vue from 'vue';
import _laxios from '../../../_laxios';

import { position } from '../../../queries';
import { AB_TEST_ID } from '../../../../../constants/abTest';

module.exports = {
  namespaced: true,
  state: {
    hash: {},
    statistic: {},
    info: {},
    positions: {},
  },
  getters: {
    statistic({ statistic }) {
      return function (id) {
        return statistic[id];
      };
    },
    allInfo({ info }) {
      return info;
    },
    moderatedInfoArr({ info }) {
      return Object.values(info).filter((item) => !!item?.postmoderation?.title) || [];
    },
    info({ info }) {
      return function (id) {
        return info[id];
      };
    },
    hash({ hash }) {
      return hash;
    },
    position(state) {
      return function (id) {
        return state.positions[id];
      };
    },
  },
  actions: {
    setNewRate({ commit, getters }, { id, value }) {
      const autoData = getters.info(id);
      if (autoData.callTracking) {
        autoData.callTracking.rate = value;
      }
      commit('advertisement', { id, payload: autoData });
    },
    updateLocalPostModeration({ commit, getters }, { id, postmoderation }) {
      const autoData = getters.info(id);
      commit('advertisement', {
        id,
        payload: {
          ...autoData,
          postmoderation,
        },
      });
    },
    position({ commit, getters }, {
      id, brandId, modelId, custom, abroad, categoryId,
    }) {
      if (!getters.position(id)) {
        return position({
          id, brandId, modelId, custom, abroad, categoryId,
        })
          .then((payload) => {
            const { data: { position = 0 } = [] } = payload || {};
            commit('position', { payload: position, id });
          });
      }
    },
    async fetch({
      commit, getters, dispatch, rootGetters,
    }, { ids, options = {} }) {
      const { rateExist } = options;
      let queryFile = 'advertisement';
      if (rateExist) {
        queryFile = 'advertisementWithRate';
      }
      // запит який витягує машинки по ид  (в розділі оголошень)
      ids = ids.filter((id) => !getters.hash[id]);
      const { 'lang/id': langId, 'shared/url/current': url } = rootGetters;
      const { 'cabinet/userInfo/_auth': _auth } = rootGetters;

      const { 'others/analytic/getABtestVariant': abTestVariant } = rootGetters;
      const hasAbTest = abTestVariant(AB_TEST_ID.FAST_PAY_BTN);

      if (ids.length) {
        const data = { query: require(`../../../_graphql/queries/${queryFile}`), variables: { ids, langId } };

        return _laxios
          .graphql
          .request({ data })
          .then(async ({ data: { get: advertisements = [] } = {} } = {}) => {
            const _ids = [];

            // Fetch additional params for advertisement
            await Promise.all(advertisements.map(async (advertisement) => {
              _ids.push(advertisement.id);
              let payload = advertisement;

              if (advertisement.status === 'ACTIVE' && hasAbTest) {
                const payDataForDefaultLevel = await dispatch('cabinet/constants/fetchPayDataForDefaultLevel', advertisement.id, { root: true });
                payload = { ...advertisement, payDataForDefaultLevel };
              }
              commit('advertisement', { id: Number(advertisement.id), payload });
            }));
            commit('hash', { ids: _ids });
          })
          .catch((e) => {
            console.error('GraphQL, vue/store/cabinet/advertisements/advertisement/index.js:', e.message);
          });
      }
    },
  },
  mutations: {
    statistic(state, { payload, id } = {}) {
      Vue.set(state.statistic, id, payload);
    },
    advertisement(state, { payload, id } = {}) {
      Vue.set(state.info, id, payload);
    },
    position(state, { payload, id } = {}) {
      Vue.set(state.positions, id, payload);
    },
    hash(state, { ids = [] } = {}) {
      for (const id of ids) {
        Vue.set(state.hash, id, true);
      }
    },
  },
};
