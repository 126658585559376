import Vue from 'vue';
import _laxios from '../../_laxios';

module.exports = {
  namespaced: true,
  state: {
    packages: {},
    prices: {},
  },
  actions: {
    packages({ commit, rootGetters }) {
      const { 'cabinet/userInfo/_auth': _auth } = rootGetters;
      return _laxios.packages
        .request()
        .then((payload) => {
          commit('packages', { payload });
        })
        .catch((e) => {
          console.error('/constants/packages/price/', e.message);
        });
    },
    prices({ commit, rootGetters }) {
      const { 'cabinet/userInfo/_auth': _auth } = rootGetters;
      return _laxios.constantsPrice
        .request()
        .then((payload) => {
          commit('prices', { payload });
        })
        .catch((e) => {
          console.error('/constants/price/', e.message);
        });
    },
    getPublicationInfo({ _, rootGetters }, autoId) {
      const { 'cabinet/userInfo/_auth': _auth } = rootGetters;
      return _laxios.getPublicationInfo
        .expandUrl({ autoId })
        .request()
        .then((payload) => payload)
        .catch((e) => {
          console.error(`/api/prices/public/publication/auto/${autoId}/`, e.message);
        });
    },
    fetchPayDataForDefaultLevel(_, autoId) {
      return _laxios.fetchPayDataForDefaultLevel
        .expandUrl({ autoId })
        .request()
        .then((payload) => payload)
        .catch((e) => {
          console.error(`/api/prices/public/level/advertiseForFixed/${autoId}/`, e.message);
        });
    },
  },
  mutations: {
    packages(state, { payload }) {
      Vue.set(state, 'packages', payload);
    },
    prices(state, { payload }) {
      Vue.set(state, 'prices', payload);
    },
  },
  getters: {
    packages({ packages }) {
      return packages;
    },
    prices({ prices }) {
      return prices;
    },
  },
};
