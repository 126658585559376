import _laxios from '../../_laxios';

module.exports = {
  namespaced: true,
  actions: {
    fetchSubscribes({ rootGetters }, { params, hasTestCookie }) {
      const { 'lang/id': langId } = rootGetters;
      const isNewApi = hasTestCookie && params.projectId === 1; // Only for used auto
      Object.assign(params, { langId });

      return _laxios
        .getSubscribePageInfo
        .expandUrl({ param: isNewApi ? 'all' : '' })
        .request({ params })
        .then((response) => {
          if (hasTestCookie) {
            const { list, data, count } = response;
            // Костилі для поєднання АРІ бу та нових авто
            const modifiedList = (params.projectId === 1 ? list : data).map(({
              id, active, subscribeString, channel, searchString: url, description: title,
            }) => {
              const hasVersion = !!subscribeString?.version;
              const isOldSearchVersion = subscribeString?.version === 2 || !hasVersion;
              const searchString = hasVersion
                ? subscribeString?.versions?.[subscribeString?.version]
                : (subscribeString?.string || url);
              const description = hasVersion
                ? subscribeString?.full
                : [subscribeString?.title, subscribeString?.description].filter(Boolean).join(', ') || title;

              return {
                id,
                active,
                searchString,
                isOldSearchVersion,
                description,
                channel,
              };
            });

            return { count, list: modifiedList };
          }

          return response;
        })
        .catch((error) => {
          console.error('subscriptions/subspage fetchSubscribe error', error.message);
        });
    },
    deleteSubscribe(_, { projectId, channelId, subsId }) {
      return _laxios
        .deleteSubscribe
        .expandUrl({ projectId, channelId, subsId })
        .request()
        .then((response) => response);
    },
  },
};
